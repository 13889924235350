import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <div className="footer" style={{
          padding: "0px", height: "142px",
          backgroundColor: "#00000063",
          // marginTop: "54px",
        }}>
          <div className="copyright" style={{paddingTop: "30px", textAlign: "center"}}>
            <p>
              <a href="https://www.bonex.net/" target="_blank" style={{textDecoration: "none", color: "white"}}>
                BoneX Margin
              </a>&nbsp;
              <strong style={{color: "white"}}>{new Date().getFullYear()}</strong>
            </p>
            <div style={{textDecoration: "none", color: "white"}}>© 2017-2024 Margin Platform</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
