import React from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { BonusClaimDto } from "../lib/drivers/dto/BonusClaimDto";
import { BonusClaimStatus } from "../lib/drivers/dto/enums/BonusClaimStatus";

export type UserBonusCardProps = {
  // claimBonus(type: any): void;
  // openClaimPopup(): void;
  // scrollToTop(): void;
  totalBonus?: number;
  totalRefferedPeople?: number;
  totalRefferedPeopleKyc?: number;
  cardValue: number | string;
  valueType: string;
};

type UserBonusCardState = {
  kycBonusButtonState: BonusClaimStatus;
};

export class UserBonusCard extends React.Component<UserBonusCardProps, UserBonusCardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      kycBonusButtonState: BonusClaimStatus.NOT_SET
    };
  }

  componentDidMount = async () => {
    const dataKycBonusClaimList: BonusClaimDto[] = await this.loadMyClaimsType("KYC");

    if (dataKycBonusClaimList && dataKycBonusClaimList.length !== 0) {
      this.setState({
        ...this.state,
        kycBonusButtonState: dataKycBonusClaimList[dataKycBonusClaimList.length - 1].status
      });
    }
  };

  // onClaimButtonClick = () => {
  //   this.props.claimBonus({ bonusType: "KYC" });
  //   this.props.openClaimPopup();
  // };

  loadMyClaimsType = async (type: string): Promise<BonusClaimDto[]> => {
    const result = await PortCentralServer.Repo.client.getMyClaimsType(type);

    return result;
  };

  render() {
    // console.log(
    //   "disabled",
    //   this.state.kycBonusButtonState === BonusClaimStatus.APPROVED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.PROCESSED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.REJECTED
    //     ? true
    //     : false
    // );

    return (
      <React.Fragment>
        <div
          className="container bonus-card col-md-3"
          style={{
            height: "fit-content",
            borderRadius: "15px",
            border: "1px solid #ffffff1a",
            padding: "30px"
          }}
        >
          <h5
            style={{
              color: "white"
            }}
          >
            <strong
              style={{
                fontSize: "30px"
              }}
            >
              {this.props.cardValue}
            </strong>
          </h5>

          <p
            style={{
              color: "white",
              margin: "28px 0px",
              fontSize: "16px",
              width: "85%"
            }}
          >
            {this.props.valueType}
          </p>

          {/* <button style={{ width: "95%", margin: "auto", backgroundColor: "#D22AEB", border: "none", borderRadius: "5px" }}>Claim</button> */}
        </div>
      </React.Fragment>
    );
  }
}
