import React from "react";
import { toast } from "react-toastify";
import { AuthCookieManager } from "../lib/AuthCookieManager";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { AxiosError } from "axios";
// import { error } from "console";

export interface AppContextEntity {
  connected: boolean; // test
  setConnected(value: boolean): void; // test

  // auth
  // loadingAuthData: boolean;
  // isLoggedIn: boolean;
  isExchangeTokenSet(): boolean;

  onConnectClick(): void;
  onDisconnectClick(): void;
  handleCentralServerError(error: any): void;

  notifyError(msg: string): void;
  notifyInfo(msg: string): void;
}

export const AppContext = React.createContext<AppContextEntity | null>(null);

export type AppProviderProps = {
  // using `interface` is also ok
  // message: string;
  children: any;
};

type AppProviderState = {
  count: number; // test
  connected: boolean; // test

  // auth
  // loadingAuthData: boolean;
  // access_token: string;
  // isLoggedIn: boolean;
};

export class AppProvider extends React.Component<AppProviderProps, AppProviderState> {
  state: AppProviderState = {
    count: 123, // test
    connected: false // test

    // auth
    // loadingAuthData: false,
    // access_token: "",
    // isLoggedIn: false
  };

  // #region
  // centralServerClient: CentralServerClient;
  // #endregion

  // #region constructor
  // constructor(props: AppProviderProps) {
  //   super(props);

  //   // this.centralServerClient = new CentralServerClient();
  // }
  // #endregion

  // #region React Events
  componentDidMount = async () => {
    // await this.setState((prevState) => {
    //   return {
    //     loadingAuthData: true
    //   };
    // });

    PortCentralServer.Repo.client.errorHandler = this.handleCentralServerError;

    // try {
    //   const token = AuthCookieManager.getToken();
    //   if (token) {
    //     // PortCentralServer.Repo.client.accessToken = token;
    //     await this.setState((prevState) => {
    //       return {
    //         // access_token: token,
    //         isLoggedIn: true
    //       };
    //     });
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   await this.setState((prevState) => {
    //     return {
    //       loadingAuthData: false
    //     };
    //   });
    // }
  };

  componentWillUnmount = () => {};
  // #endregion

  // #region Handlers
  onConnectClick = async () => {
    await this.checkConnected(true);
  };

  onDisconnectClick = async () => {};
  // #endregion

  // #region Auth
  isExchangeTokenSet = (): boolean => {
    const token = AuthCookieManager.getExchangeToken();

    if (token && token !== undefined) {
      return true;
    }

    return false;
  };
  // #endregion

  // #region System Functions
  handleCentralServerError = (error: any) => {
    const axiosError = error as AxiosError;
    if (axiosError != null) {
      let errorMessage: string = "";

      if (axiosError.response && axiosError.response.data) {
        const data = axiosError.response.data as any;
        if (data && data.message) {
          errorMessage = data.message;
        }
      }
      
      if (errorMessage.length > 0) {
        // this.notifyError(`Error processing request to central: ${errorMessage}`);
        console.error(errorMessage);
      } else {
        // this.notifyError(`Error processing request to central: ${axiosError.message}`);
        console.error(axiosError.message);
      }

      if (axiosError.response) {
        if (axiosError.response.status == 401) {
          AuthCookieManager.removeExchangeCookies();
          AuthCookieManager.removeSessionCookies();
          const event = new CustomEvent("redirectEvent", { detail: "/" });
          document.dispatchEvent(event);
        }
      }
    }

    console.error(error);
  };
  // #endregion

  // #region Helper functions
  checkConnected = async (requested: boolean) => {};
  // #endregion

  // #region State Setters
  setConnected = async (value: boolean) => {
    await this.setState(
      (prevState) => {
        return {
          connected: value
        };
      },
      () => {}
    );
  };
  // #endregion

  // #region functions
  notifyError = (msg: string) => {
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };

  notifyInfo = (msg: string) => {
    toast.info(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });
  };
  // #endregion

  render() {
    const contextEntity: AppContextEntity = {
      connected: this.state.connected,
      setConnected: this.setConnected,

      // auth
      // loadingAuthData: this.state.loadingAuthData,
      // isLoggedIn: this.state.isLoggedIn,
      isExchangeTokenSet: this.isExchangeTokenSet,

      onConnectClick: this.onConnectClick,
      onDisconnectClick: this.onDisconnectClick,
      handleCentralServerError: this.handleCentralServerError,

      notifyError: this.notifyError,
      notifyInfo: this.notifyInfo
    };

    return <AppContext.Provider value={contextEntity}>{this.props.children}</AppContext.Provider>;
  }
}
