import React from "react";

export type JoinBonexPartProps = {
  isLogged: boolean;
  onQrCodeButtonClick(): void;
};

type JoinBonexPartState = {
  activeIndex: number;
};

export class JoinBonexPart extends React.Component<JoinBonexPartProps, JoinBonexPartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#491370",
          height: "200px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            margin: "auto",
            textAlign: "center"
          }}
        >
          <h3
            style={{
              color: "white"
            }}
          >
             Join Bonex Affiliate Program to unlock Up to 50% Commission 
           {/* Get your 20$ KYC Bonus*/}
          </h3>
          {
            this.props.isLogged ? (
              <button
                type="button"
                // onClick={this.props.onQrCodeButtonClick}
                // className="heading-button"
                onClick={() => window.open("https://www.bonex.net/en_US/assets/exchangeAccount")}
                style={{
                  padding: "5px 15px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#D22AEB",
                  marginTop: "30px",
                  fontWeight: "600",
                  color: "white"
                }}
              >
                {" "}
                Start Earning Now
              </button>
            ) : (
              <button
                type="button"
                onClick={() => window.open("https://onboarding.bonex.net")}
                // className="heading-button"
                style={{
                  padding: "5px 15px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#D22AEB",
                  marginTop: "30px",
                  fontWeight: "600",
                  color: "white"
                }}
              >
                {" "}
                Login in Bonex.net
              </button>
            )
            // <a href="https://www.bonex.net/en_US/login">Log in Bonex.net</a>
          }
          {/* <button
            style={{
              padding: "5px 15px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#D22AEB",
              marginTop: "30px",
              fontWeight: "600"
            }}
          >
            Start Earning Now
          </button> */}
        </div>
      </div>
    );
  }
}
