import React from "react";
import { Layout } from "../layout";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { BonusCommissionIndexDto } from "../lib/drivers/dto/BonusCommissionIndexDto";
import { AppBonusUserInfoDto } from "../lib/drivers/dto/AppBonusUserInfoDto";
import { HeadingPart } from "../components/HeadingPart";
import { DashboardPart } from "../components/DashboardPart";
import { AppContext, AppProvider } from "../context/AppProvider";
import { LoginPopup } from "../components/LoginPopup";
import { SurveyPopup } from "../components/SurveyPopup";
import { AppBonusCommissionSurveyResponseDto } from "../lib/drivers/dto/AppBonusCommissionSurveyResponseDto";
import { AppBonusCommissionSurveyRequestDto } from "../lib/drivers/dto/AppBonusCommissionSurveyRequestDto";
import { AuthCookieManager } from "../lib/AuthCookieManager";
import { BonusesPart } from "../components/BonusesPart";
import { ClaimBonusPopup } from "../components/ClaimBonusPopup";
import { QrCodePopup } from "../components/QrCodePopup";
import { BonusClaimStatus } from "../lib/drivers/dto/enums/BonusClaimStatus";
import { HeadingPartNew } from "../components/HeadingPartNew";
import { BonusesPartNew } from "../components/BonusesPartNew";
import { HowToInvitePart } from "../components/HowToInvitePart";
import { FaqPart } from "../components/FaqPart";
import { TermsAndConditionsPart } from "../components/TermsAndConditionsPart";
import { JoinBonexPart } from "../components/JoinBonexPart";
import { UserBonusesPart } from "../components/UserBonusesPart";
import { RankingPopup } from "../components/RankingPopup";
import { AppBonusClaimRankingInfo } from "../lib/drivers/dto/AppBonusClaimRankingInfo";
export type HomePageNewProps = {};

type HomePageNewState = {
  bonusCommissionUserId: string;
  platformUserUid: string;
  status: string;
  totalReferralCount: number;
  totalReferralCountKyc: number;
  percentageShareInviter: number;
  maxReferralLevel: number;
  totalBonusAmount: number;
  inviteCode: string;
  loginPopupState: {
    isOpen: boolean;
  };
  surveyPopupState: {
    isOpen: boolean;
  };
  qrCodePopupState: {
    isOpen: boolean;
  };
  rankingPopupState: {
    isOpen: boolean;
  };
  ranking: Array<AppBonusClaimRankingInfo>;
  claimBonusPopupState: {
    isOpen: boolean;
  };
  claimBonusMessage: string;
  surveyAnswered: boolean;
  currMonthInvitedPeople: number;
  currMonthInvitedPeopleKYC: number;
  prevMonthInvitedPeople: number;
  prevMonthInvitedPeopleKYC: number;
  currMonthTotalBonus: number;
  prevMonthTotalBonus: number;
  currMonthTotalBonusAmount: number;
  prevMonthTotalBonusAmount: number;
  currBonusAmount: number;
};

export class HomePageNew extends React.Component<HomePageNewProps, HomePageNewState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      bonusCommissionUserId: "",
      platformUserUid: "",
      status: "",
      totalReferralCount: 0,
      totalReferralCountKyc: 0,
      percentageShareInviter: 0,
      maxReferralLevel: 1,
      totalBonusAmount: 0,
      inviteCode: "",
      loginPopupState: {
        isOpen: false
      },
      surveyPopupState: {
        isOpen: false
      },
      qrCodePopupState: {
        isOpen: false
      },
      rankingPopupState: {
        isOpen: false
      },
      ranking: [],
      claimBonusPopupState: {
        isOpen: false
      },
      claimBonusMessage: "",
      surveyAnswered: false,
      currMonthInvitedPeople: 0,
      currMonthInvitedPeopleKYC: 0,
      prevMonthInvitedPeople: 0,
      prevMonthInvitedPeopleKYC: 0,
      currMonthTotalBonus: 0,
      prevMonthTotalBonus: 0,
      currMonthTotalBonusAmount: 0,
      prevMonthTotalBonusAmount: 0,
      currBonusAmount: 0
    };
  }

  componentDidMount = async () => {
    const bonusRankings: AppBonusClaimRankingInfo[] = await this.loadBonusClaimRanking();
    if (this.context.isExchangeTokenSet()) {
      const dataUserItem: AppBonusUserInfoDto = await this.loadBonusCommissionUser();
      const dataCurrIndexItemNew: BonusCommissionIndexDto = await this.loadBonusCommissionIndexCurr();

      if (dataCurrIndexItemNew) {
        this.setState({
          ...this.state,
          currBonusAmount: dataCurrIndexItemNew.bonusAmount,
          ranking: bonusRankings
        });
      }
    } else {
      this.setState({
        ...this.state,
        loginPopupState: {
          isOpen: true
        },
        ranking: bonusRankings
      });
    }
  };

  componentDidUpdate(prevProps: Readonly<HomePageNewProps>, prevState: Readonly<HomePageNewState>, snapshot?: any): void {
    if (this.state.claimBonusPopupState.isOpen || this.state.qrCodePopupState.isOpen || this.state.rankingPopupState.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }

  loadBonusClaimRanking = async (): Promise<AppBonusClaimRankingInfo[]> => {
    const result = await PortCentralServer.Repo.client.getBonusClaimRanking();
    return result;
  };

  loadBonusCommissionUser = async (): Promise<AppBonusUserInfoDto> => {
    const result = await PortCentralServer.Repo.client.getBonusCommissionUser();
    // console.log("resulttttt", result);
    if (result) {
      this.setState({
        totalBonusAmount: result.totalBonusAmount,
        inviteCode: result.inviteCode,
        totalReferralCount: result.totalReferralCount,
        totalReferralCountKyc: result.totalReferralCountKyc,
        surveyAnswered: result.surveyAnsweredYn
      });
    }

    return result;
  };

  saveSurvey = async (survey: AppBonusCommissionSurveyRequestDto): Promise<AppBonusCommissionSurveyResponseDto> => {
    const result = await PortCentralServer.Repo.client.saveSurvey(survey);
    // this.setState({surveyAnswered: true})
    return result;
  };

  loadBonusCommissionIndexCurr = async (): Promise<BonusCommissionIndexDto> => {
    const result = await PortCentralServer.Repo.client.getBonusCommissionIndexCurr();
    return result;
  };

  claimBonus = async (request: any): Promise<any> => {
    if (this.context.isExchangeTokenSet()) {
      const result = await PortCentralServer.Repo.client.claimBonus(request);
      let message = "Your claim will be processed soon!";
      if (result) {
        if (result.status == BonusClaimStatus.PROCESSED || result.status == BonusClaimStatus.APPROVED) {
          message = "You will receive your bonus soon!";
        } else if (result.status == BonusClaimStatus.NOT_SET || result.status == BonusClaimStatus.REJECTED) {
          message = "Your claim will be processed soon!";
        }
      }

      this.setState({
        ...this.state,
        claimBonusMessage: message
      });
      return result;
    }
  };

  onClaimBonusPopupOpenClick = async () => {
    this.setState({
      ...this.state,
      claimBonusPopupState: {
        isOpen: true
      }
    });
  };

  loadMyClaims = async (): Promise<any> => {
    if (this.context.isExchangeTokenSet()) {
      const result = await PortCentralServer.Repo.client.getMyClaims();

      return result;
    }
  };

  onPopupCloseClick = async () => {
    this.setState({
      ...this.state,
      loginPopupState: {
        isOpen: false
      }
    });
  };

  onBonusPopupCloseClick = async () => {
    this.setState({
      ...this.state,
      claimBonusPopupState: {
        isOpen: false
      }
    });
  };

  // scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }

  onSurveyPopupCloseClick = async () => {
    AuthCookieManager.createSurveySessionCookies();

    this.setState({
      ...this.state,
      surveyPopupState: {
        isOpen: false
      },
      surveyAnswered: true
    });
  };

  onQrCodeButtonClick = () => {
    this.setState({
      ...this.state,
      qrCodePopupState: {
        isOpen: true
      }
    });
  };

  onCloseQrCodePopup = () => {
    this.setState({
      ...this.state,
      qrCodePopupState: {
        isOpen: false
      }
    });
  };

  onCloseRankPopup = () => {
    this.setState({
      ...this.state,
      rankingPopupState: {
        isOpen: false
      }
    });
  };

  onOpenRankPopupClick = () => {
    this.setState({
      ...this.state,
      rankingPopupState: {
        isOpen: true
      }
    });
  };

  render() {
    const surveySkippedValue = AuthCookieManager.getSurveySkippedValue();
    // this.state.bonusAmount // null initially -> whatever the result is;
    // console.log("THIS STATE", this.state);

    return (
      <React.Fragment>
        <Layout>
          {/* <ClaimBonusPopup /> */}
          <HeadingPartNew
            commissionPercentage={this.state.percentageShareInviter}
            invitedFirends={this.state.totalReferralCount}
            invitedFriendsKYC={this.state.totalReferralCountKyc}
            inviteCode={this.state.inviteCode}
            onQrCodeButtonClick={this.onQrCodeButtonClick}
            totalBonusAmount={this.state.totalBonusAmount}
            isLogged={this.context.isExchangeTokenSet()}
            onOpenRankPopupClick={this.onOpenRankPopupClick}
            ranking={this.state.ranking}
          />

          <BonusesPartNew
            claimBonus={this.claimBonus}
            openClaimPopup={this.onClaimBonusPopupOpenClick}
            isLogged={this.context.isExchangeTokenSet()}
            // scrollToTop={this.scrollToTop}
          />
          {this.context.isExchangeTokenSet() && (
            <UserBonusesPart
              claimBonus={this.claimBonus}
              openClaimPopup={this.onClaimBonusPopupOpenClick}
              totalBonus={this.state.totalBonusAmount}
              totalRefferedPeople={this.state.totalReferralCount}
              totalRefferedPeopleKyc={this.state.totalReferralCountKyc}
            />
          )}
          <HowToInvitePart />
          <FaqPart />
          <TermsAndConditionsPart />
          <JoinBonexPart isLogged={this.context.isExchangeTokenSet()} onQrCodeButtonClick={this.onQrCodeButtonClick} />
          {/* <HeadingPart
            commissionPercentage={this.state.percentageShareInviter}
            invitedFirends={this.state.totalReferralCount}
            invitedFriendsKYC={this.state.totalReferralCountKyc}
            inviteCode={this.state.inviteCode}
            onQrCodeButtonClick={this.onQrCodeButtonClick}
            totalBonusAmount={this.state.totalBonusAmount}
          /> */}
          {/* <DashboardPart
            totalCommission={this.state.currBonusAmount}
            invitedFirends={this.state.totalReferralCount}
            invitedFriendsKYC={this.state.totalReferralCountKyc}
            currMonthTotalBonusAmount={this.state.currMonthTotalBonusAmount}
            openClaimPopup={this.onClaimBonusPopupOpenClick}
            isLogged={this.context.isExchangeTokenSet()}
            claimBonus={this.claimBonus}

            // prevMonthTotalBonusAmount={this.state.currMonthTotalBonusAmount}
          /> */}

          {/* <ReferralsPart /> */}
          {/* {this.state.loginPopupState.isOpen && <LoginPopup closePopup={this.onPopupCloseClick} />} */}
          {this.state.qrCodePopupState.isOpen && <QrCodePopup closePopup={this.onCloseQrCodePopup} referralCode={this.state.inviteCode} />}
          {this.state.claimBonusPopupState.isOpen && this.context.isExchangeTokenSet() && (
            <ClaimBonusPopup message={this.state.claimBonusMessage} closePopup={this.onBonusPopupCloseClick} />
          )}
          {/* {!surveySkippedValue && !this.state.surveyAnswered && this.state.surveyPopupState.isOpen && (
            <SurveyPopup closePopup={this.onSurveyPopupCloseClick} saveSurvey={this.saveSurvey} />
          )} */}
          {this.state.rankingPopupState.isOpen && <RankingPopup closePopup={this.onCloseRankPopup} ranking={this.state.ranking}/>}
        </Layout>
      </React.Fragment>
    );
  }
}
