import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { CentralServerEndpointKind, CentralServerPathParamKind } from './CentralServerClientEnums';
import { AppBonusClaimRankingInfo } from './dto/AppBonusClaimRankingInfo';
import { AppBonusClaimRequest } from './dto/AppBonusClaimRequestDto';
import { AppBonusCommissionSurveyRequestDto } from './dto/AppBonusCommissionSurveyRequestDto';
import { AppBonusCommissionSurveyResponseDto } from './dto/AppBonusCommissionSurveyResponseDto';
import { AppBonusUserClaimInfoDto } from './dto/AppBonusUserClaimInfoDto';
import { AppBonusUserInfoDto } from './dto/AppBonusUserInfoDto';
import { BonusClaimDto } from './dto/BonusClaimDto';
import { BonusCommissionIndexDto } from './dto/BonusCommissionIndexDto';
import { IBonusCommissionConfig } from './dto/IBonusCommissionConfig';


type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_CENTRAL_BASE_URL);
    }
    // #endregion

    // #region Bonus Commissions
    async getBonusCommissionConfig(): Promise<IBonusCommissionConfig> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_commissions_config.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: IBonusCommissionConfig = data as IBonusCommissionConfig;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getBonusCommissionUser(): Promise<AppBonusUserInfoDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_user.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: AppBonusUserInfoDto = data as AppBonusUserInfoDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getBonusCommissionIndexCurr(): Promise<BonusCommissionIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_commissions_index_current.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: BonusCommissionIndexDto = data as BonusCommissionIndexDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async saveSurvey(model: AppBonusCommissionSurveyRequestDto): Promise<AppBonusCommissionSurveyResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_commissions_survey.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AppBonusCommissionSurveyResponseDto = data as AppBonusCommissionSurveyResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async claimBonus(model: AppBonusClaimRequest): Promise<BonusClaimDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_claims_claim.toString(), null, null);
            const headers = this.getHeaders();
            const { data } = await axios.post(url, model, headers);
            const result: BonusClaimDto = data as BonusClaimDto;

            return result
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getMyClaims(): Promise<BonusClaimDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_claims_my_claims.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: BonusClaimDto[] = data as BonusClaimDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getMyClaimsType(type: string): Promise<BonusClaimDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_claims_my_claims_type.toString(), [
                new UrlParamPair(CentralServerPathParamKind.type, type)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: BonusClaimDto[] = data as BonusClaimDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getBonusesForClaim(): Promise<AppBonusUserClaimInfoDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_bonuses_for_claim.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: AppBonusUserClaimInfoDto[] = data as AppBonusUserClaimInfoDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getBonusClaimRanking(): Promise<AppBonusClaimRankingInfo[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_bonuses_claims_ranking.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: AppBonusClaimRankingInfo[] = data as AppBonusClaimRankingInfo[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getExchangeToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                "x-exchange-token": `${token}`
            };
        }

        return headers;
    }
    // #endregion
}