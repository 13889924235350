import React from "react";

export type HowToInvitePartProps = {};

type HowToInvitePartState = {};

export class HowToInvitePart extends React.Component<HowToInvitePartProps, HowToInvitePartState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {}

  render() {
    return (
      <React.Fragment>
        <div
          className="row how-to-invite"
          style={{
            width: "100%",
            margin: "auto",
            height: "fit-content",
            padding: "50px 0px"
          }}
        >
          <h2
            style={{
              color: "white",
              width: "89%",
              margin: "auto",
              marginBottom: "60px"
            }}
          >
            How to Invite
          </h2>
          <div
            className="row"
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <img
                src="/images/how-to-invite-1.png"
                style={{
                  height: "72px",
                  marginBottom: "15px"
                }}
              />
              <h3 style={{ color: "white" }}>Share Your Code and Link</h3>
              <p style={{ color: "#ffffff9c" }}>You can invite your friends to use all Bonex products with just one referral code.</p>
            </div>
            <div
              className="col-md-1 how-to-invite-arrow"
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src="/images/how-to-invite-arrow.png" style={{ width: "80px" }} />
            </div>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <img
                src="/images/how-to-invite-2.png"
                style={{
                  height: "72px",
                  marginBottom: "15px"
                }}
              />
              <h3 style={{ color: "white" }}>Connect with Your Friends</h3>
              <p style={{ color: "#ffffff9c" }}>Your friends will be associated with you after they sign up.</p>
            </div>
            <div
              className="col-md-1 how-to-invite-arrow"
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src="/images/how-to-invite-arrow.png" style={{ width: "80px" }} />
            </div>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <img
                src="/images/how-to-invite-3.png"
                style={{
                  height: "72px",
                  marginBottom: "15px"
                }}
              />
              <h3 style={{ color: "white" }}>Get Multiple Rewards</h3>
              <p style={{ color: "#ffffff9c" }}>Automatically get Trading Boost, KYC Rewards & Commission on every trade they make across Bonex Futures.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
