
export enum CentralServerPathParamKind {
    year = "{year}",
    month = "{month}",
    type = "{type}",
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // bonus commissions
    app_bonuses_commissions_config = "app/bonuses/commissions/config",
    app_bonuses_user = "app/bonuses/user",
    app_bonuses_commissions_index_year_month = "app/bonuses/commissions/index/{year}/{month}",
    app_bonuses_commissions_index_history = "app/bonuses/commissions/index/history",
    app_bonuses_commissions_index_current = "app/bonuses/commissions/index/current",
    app_bonuses_commissions_survey = "app/bonuses/commissions/survey",
    app_bonuses_claims_claim = "app/bonuses/claims/claim",
    app_bonuses_claims_my_claims = "app/bonuses/claims/my-claims",
    app_bonuses_claims_my_claims_type = "app/bonuses/claims/my-claims/{type}",
    app_bonuses_bonuses_for_claim = "app/bonuses/bonuses-for-claim",
    app_bonuses_claims_ranking = "app/bonuses/claims/ranking"
}