import React, { FormEvent } from "react";
import { AppBonusCommissionSurveyRequestDto } from "../lib/drivers/dto/AppBonusCommissionSurveyRequestDto";
import QRCode from "qrcode.react";

// Props & State
export type QrCodePopupProps = {
  // closePopup(): void;
  // saveSurvey(model: AppBonusCommissionSurveyRequestDto): void;
  // message: string,
  referralCode: string;
  closePopup(): void;
};

type QrCodePopupState = {
  // message: string
  referralLink: string;
};

// interface QrCodePopupState {
//     selectedAnswers: { [key: number]: string[] };
//   }

export class QrCodePopup extends React.Component<QrCodePopupProps, QrCodePopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      referralLink: "https://bonex.net/register?inviteCode=" + this.props.referralCode
    };
  }

  componentDidMount(): void {}

  render() {
    return (
      <React.Fragment>
        <div>
          <div
            className="bonus-popup-wrapper"
            style={{
              position: "absolute",
              top: "0%",
              left: "0%",
              width: "100%",
              height: "231%",
              backdropFilter: "blur(10px)",
              zIndex: "3"
            }}
          >
            <div className="qr-popup">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white"
                }}
                onClick={() => this.props.closePopup()}
              >
                X
              </button>
              <div style={{ width: "94%", margin: "auto", marginTop: "10px" }}>
                <h2
                  style={{
                    color: "white",
                    // width: "80%",
                    margin: "auto",
                    textAlign: "center",
                    marginBottom: "35px",
                    marginTop: "15px"
                    // fontSize: "25px"
                  }}
                >
                  Invite a Friend: <strong style={{ color: "#b82bfa", fontSize: "30px" }}>Scan Now</strong>!
                </h2>
                <div
                  className="qr-code-div"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    background: "white",
                    padding: "16px 16px 13px 16px",
                    borderRadius: "10px",
                    boxShadow: "4px 3px 3px 1px #b82bfa"
                  }}
                >
                  <QRCode value={this.state.referralLink} size={200} />
                </div>
                {/* <div style={{ color: "white", textAlign: "center", marginTop: "20px" }}>Scan this QR code to use the referral link.</div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
