import React from "react";

export type FaqPartProps = {};

type FaqPartState = {
  activeIndex: number;
};

export class FaqPart extends React.Component<FaqPartProps, FaqPartState> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {};
  // }

  // componentDidMount(): void {}

  // render() {
  //   return (
  //     <React.Fragment>
  //       <div className="row how-to-invite" style={{ width: "100%", margin: "auto", height: "fit-content", padding: "50px 0px" }}>
  //         <h2 style={{ color: "white", width: "89%", margin: "auto", marginBottom: "60px" }}>FAQ(s)</h2>

  //       </div>
  //     </React.Fragment>
  //   );
  // }
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    const faqs = [
      {
        question: "What is the Trading Boost?",
        answer: "The Trading Boost is a bonus for users who complete KYC verification. It offers rewards based on your deposit, trading volume, and holding period."
      },
      {
        question: "How do I claim the Trading Boost?",
        answer: [
          "Deposit: Add money to your futures wallet.",
          "Trade: Reach the required trading volume.",
          "Hold: Keep your deposit for at least 7 days.",
          "Get: Message us to claim your bonus."
        ]
      },
      {
        question: "What bonuses can I get with the Trading Boost?",
        answer: [
          "Deposit 100, volume 4,000, hold 7 days → Get 30$ Bonus",
          "Deposit 300, volume 15,000, hold 7 days → Get 90$ Bonus",
          "Deposit 1,000, volume 30,000, hold 7 days → Get 350$ Bonus",
          "Deposit 5,000, volume 250,000, hold 7 days → Get 1,000$ Bonus",
          "Deposit 10,000, volume 600,000, hold 7 days → Get 2,000$ Bonus",
          "Deposit 50,000, volume 5,000,000, hold 7 days → Get 10,000$ Bonus"
        ]
      },
      {
        question: "Are there any restrictions?",
        answer: ["Applies only to new deposits.", "Must be used for trading.", "Limited to your first deposit.", "Accounts are monitored for unusual activity."]
      },
      {
        question: "What are the general terms for bonuses?",
        answer: [
          "Limited Offers: First-come, first-served.",
          "KYC Compliance: Must follow Identity Verification Level 1.",
          "No Misconduct: Abusive behaviors like multi-accounting will lead to termination.",
          "Details: Check the Latest Promotions, Bonus, and Coupons FAQ on the BoneX Margin platform.",
          "Regional Restrictions: Some regions may have restrictions.",
          "Monitoring: Updates every four hours.",
          "Security: Accounts may be locked and funds frozen if suspicious activity is detected."
        ]
      },
      {
        question: "What is the Bonex Affiliate Program?",
        answer: "It’s a program where you can earn commissions and rewards by referring others to Bonex Margin trading."
      },
      {
        question: "How does the Bonex Affiliate Program work?",
        answer: [
          "Get Your Link: Find it in your user profile.",
          "Share It: Promote your link on social media, websites, etc.",
          "KYC Verification: Both you and your affiliates must complete KYC.",
          "Earn Commissions: Get 50% commission on transactions made by your affiliates."
        ]
      },
      {
        question: "What are the benefits?",
        answer: [
          "Unlimited Earnings: No cap on earnings, get paid anytime.",
          "Open to Everyone: Anyone can join and earn.",
          "Track Your Progress: Use the dashboard for regular updates every two hours.",
          "Exclusive Promotions: Access special offers for top affiliates."
        ]
      },
      {
        question: "Are there any requirements?",
        answer: "Yes, both you and your referred affiliates must complete KYC verification to participate and earn commissions."
      }
    ];

    return (
      <div
        style={{
          backgroundColor: "#060010",
          height: "fit-content"
        }}
      >
        <div className="container">
          <div className="row">
            <h1
              className="col-md-10"
              style={{
                margin: "auto",
                marginTop: "70px",
                marginBottom: "30px",
                color: "white"
              }}
            >
              FAQ(s)
            </h1>
            <div style={{ marginBottom: "70px" }}>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="col-md-10"
                  style={{
                    margin: "auto",
                    borderBottom: "1px solid #ffffff5c"
                  }}
                >
                  <div
                    className="row"
                    onClick={() => this.toggleAnswer(index)}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      margin: "10px 0"
                    }}
                  >
                    <h5
                      className="col-sm-11"
                      style={{
                        color: "white",
                        margin: "15px 0px",
                        fontWeight: "400",
                        fontFamily: "sans-serif"
                      }}
                    >
                      {faq.question}
                    </h5>
                    <h5
                      className="col-sm-1"
                      style={{
                        color: "#ffffff5c",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "400"
                      }}
                    >
                      ↓
                    </h5>
                  </div>
                  {this.state.activeIndex === index && (
                    <div style={{ margin: "10px 0 20px 10px" }}>
                      {Array.isArray(faq.answer) ? (
                        <ul style={{ whiteSpace: "no-wrap" }}>
                          {faq.answer.map((indexAnswer) => (
                            <li>
                              <p
                                style={{
                                  color: "white",
                                  fontWeight: "400"
                                }}
                              >
                                {indexAnswer}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p
                          style={{
                            color: "white",
                            fontWeight: "400"
                          }}
                        >
                          {faq.answer}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ))}
              <div className="text-center mt-5">
                <a href="https://www.bonex.net/en_US/cms/rewards-hub">Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
