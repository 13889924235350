import React from "react";

export type TermsAndConditionsPartProps = {};

type TermsAndConditionsPartState = {
  activeIndex: number;
};

export class TermsAndConditionsPart extends React.Component<TermsAndConditionsPartProps, TermsAndConditionsPartState> {

  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    return (
      <div style={{ backgroundColor: "#060010", height: "fit-content" }}>
        <div className="container toc-part">
          <div className="row">
            <h1 className="col-md-10" style={{ margin: "auto", marginTop: "70px", marginBottom: "30px", color: "white" }}>
              TermsAndConditions(s)
            </h1>
            <div style={{ margin: "auto", marginBottom: "50px" }} className="col-md-10">
              <div className="eligibility" style={{ color: "white" }}>
                <h2 style={{ color: "white" }}>Eligibility</h2>
                <p style={{ color: "white" }}>
                  Only registered users who have completed KYC (Know Your Customer) verification can participate in the Trading Boost and other promotions.
                </p>

                <h3 style={{ color: "white" }}>Trading Boost</h3>
                <ul style={{ color: "white" }}>
                  <li>The Trading Boost applies only to new deposits.</li>
                  <li>Bonuses must be used for trading purposes only.</li>
                  <li>The bonus will be deposited after holding the deposited sum in the account for at least 7 days.</li>
                  <li>You must make at least one trade within 7 days of depositing.</li>
                  <li>Your deposit and trading volume must be in the futures wallet.</li>
                  <li>The boost is valid only for your first deposit.</li>
                  <li>Message us to claim your trading boost.</li>
                </ul>

                <h3 style={{ color: "white" }}>General Terms</h3>
                <ul style={{ color: "white" }}>
                  <li>All promotions and bonuses are available on a first-come, first-served basis.</li>
                  <li>Users must comply with Identity Verification Level 1 (KYC Compliance).</li>
                  <li>The KYC bonus remains valid in the futures wallet for 30 days following its crediting</li>
                  <li>Engaging in abusive behaviors such as multi-accounting will result in immediate termination from the program.</li>
                  <li>For comprehensive rules and benefits, refer to the Latest Promotions, Bonus, and Coupons FAQ on the BoneX Margin platform.</li>
                  <li>Trading Vouchers for Spot are not compatible with a Unified Trading Account.</li>
                  <li>Users from specific regions may face restrictions.</li>
                  <li>Progress updates are made every four hours.</li>
                  <li>Bonus Withdrawal: Profit earned from a bonus can be withdrawn one month after the bonus is received.</li>
                  <li>Accounts may be locked and funds frozen if suspicious activity is detected to ensure the safety and integrity of our platform.</li>
                </ul>

                <h3 style={{ color: "white" }}>Bonex Affiliate Program</h3>
                <ul style={{ color: "white" }}>
                  <li>Both you and your affiliates must complete the KYC verification process.</li>
                  <li>Earn a 50% commission on every transaction made by your affiliates.</li>
                  <li>There is no cap on how much you can earn.</li>
                  <li>Commissions and performance metrics can be tracked using the dashboard, which updates every two hours.</li>
                  <li>Access exclusive promotions and bonuses tailored for top affiliates.</li>
                  <li>For any questions or further details, please contact our support team.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
