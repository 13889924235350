import React from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { BonusClaimDto } from "../lib/drivers/dto/BonusClaimDto";
import { BonusClaimStatus } from "../lib/drivers/dto/enums/BonusClaimStatus";
import { BonusCard } from "./BonusCard";
import { UserBonusCard } from "./UserBonusCard";
import { ClaimBonusPopup } from "./ClaimBonusPopup";

export type UserBonusesPartProps = {
  claimBonus(type: any): void;
  openClaimPopup(): void;
  // scrollToTop(): void;
  totalBonus: number;
  totalRefferedPeople: number;
  totalRefferedPeopleKyc: number;
};

type UserBonusesPartState = {
  kycBonusButtonState: BonusClaimStatus;
};

export class UserBonusesPart extends React.Component<UserBonusesPartProps, UserBonusesPartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      kycBonusButtonState: BonusClaimStatus.NOT_SET
    };
  }

  componentDidMount = async () => {
    const dataKycBonusClaimList: BonusClaimDto[] = await this.loadMyClaimsType("KYC");

    if (dataKycBonusClaimList && dataKycBonusClaimList.length !== 0) {
      this.setState({
        ...this.state,
        kycBonusButtonState: dataKycBonusClaimList[dataKycBonusClaimList.length - 1].status
      });
    }
  };

  onClaimButtonClick = () => {
    this.props.claimBonus({ bonusType: "KYC" });
    this.props.openClaimPopup();
  };

  loadMyClaimsType = async (type: string): Promise<BonusClaimDto[]> => {
    const result = await PortCentralServer.Repo.client.getMyClaimsType(type);

    return result;
  };

  render() {
    // console.log(
    //   "disabled",
    //   this.state.kycBonusButtonState === BonusClaimStatus.APPROVED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.PROCESSED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.REJECTED
    //     ? true
    //     : false
    // );

    return (
      <React.Fragment>
        <div className="">
          <div
            className="row"
            style={{
              backgroundColor: "#060010",
              height: "fit-content",
              width: "100%",
              margin: "auto",
              padding: "50px 8%"
            }}
          >
            <h2 style={{ color: "white" }}>Your Bonuses</h2>
            <div
              className="row"
              style={{
                backgroundColor: "#060010",
                width: "100%",
                margin: "auto",
                padding: "50px 0%"
              }}
            >
              <UserBonusCard cardValue={this.props.totalBonus ? `${this.props.totalBonus} USDT` : 0} valueType={"Total Bonus"}/>
              <UserBonusCard cardValue={this.props.totalRefferedPeople ? this.props.totalRefferedPeople : 0} valueType={"Your Friends"}/>
              <UserBonusCard cardValue={this.props.totalRefferedPeopleKyc ? this.props.totalRefferedPeopleKyc : 0} valueType={"Active Friends"} />
              {/* <ClaimBonusPopup /> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
