import React from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { BonusClaimDto } from "../lib/drivers/dto/BonusClaimDto";
import { BonusClaimStatus } from "../lib/drivers/dto/enums/BonusClaimStatus";

const BONUS_TYPES = {
  COMMISSION: {
    smallHeading: "Get",
    value: "50%",
    valueText: "Commission",
    subtitle: "On every trade by your affiliates",
    buttonTitle: "Claim"
    // textForAmount: "You receive:"
  },
  BOOST: {
    smallHeading: "One time",
    value: "10,000",
    valueText: "USDT Trading Boost in Rewards",
    subtitle: "Deposit, Trade, Hold for 7 Days & Get",
    buttonTitle: "Claim"
  },
  KYC: {
    smallHeading: "One time",
    value: "20",
    valueText: "USDT for completing KYC",
    subtitle: "Complete KYC, Get $20 within 3 days",
    buttonTitle: "Claim"
  }
};

export type BonusCardProps = {
  type: string;
  claimBonus(type: any): void;
  openClaimPopup(): void;
  // scrollToTop(): void;
  isLogged: boolean;
  canClaimYn: boolean;
  message: string;
  generatedBonus: number;
  href: string
};

type BonusCardState = {
  kycBonusButtonState: BonusClaimStatus;
  type: string;
  value: number | string;
  valueText: string;
  subtitle: string;
  buttonTitle: string;
  smallHeading: string;
};

export class BonusCard extends React.Component<BonusCardProps, BonusCardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      kycBonusButtonState: BonusClaimStatus.NOT_SET,
      type: "",
      value: "",
      valueText: "",
      subtitle: "",
      buttonTitle: "",
      smallHeading: ""
    };
  }

  componentDidMount = async () => {
    const dataKycBonusClaimList: BonusClaimDto[] = await this.loadMyClaimsType("KYC");

    if (dataKycBonusClaimList && dataKycBonusClaimList.length !== 0) {
      this.setState({
        ...this.state,
        kycBonusButtonState: dataKycBonusClaimList[dataKycBonusClaimList.length - 1].status
      });
    }

    if (this.props.type === "COMMISSION") {
      this.setState({
        ...this.state,
        value: BONUS_TYPES.COMMISSION.value,
        valueText: BONUS_TYPES.COMMISSION.valueText,
        subtitle: BONUS_TYPES.COMMISSION.subtitle,
        buttonTitle: BONUS_TYPES.COMMISSION.buttonTitle,
        smallHeading: BONUS_TYPES.COMMISSION.smallHeading
      });
    } else if (this.props.type === "BOOST") {
      this.setState({
        ...this.state,
        value: BONUS_TYPES.BOOST.value,
        valueText: BONUS_TYPES.BOOST.valueText,
        subtitle: BONUS_TYPES.BOOST.subtitle,
        buttonTitle: BONUS_TYPES.BOOST.buttonTitle,
        smallHeading: BONUS_TYPES.BOOST.smallHeading
      });
    } else if (this.props.type === "KYC") {
      this.setState({
        ...this.state,
        value: BONUS_TYPES.KYC.value,
        valueText: BONUS_TYPES.KYC.valueText,
        subtitle: BONUS_TYPES.KYC.subtitle,
        buttonTitle: BONUS_TYPES.KYC.buttonTitle,
        smallHeading: BONUS_TYPES.KYC.smallHeading
      });
    }
  };

  onClaimButtonClick = () => {
    this.props.claimBonus({ bonusType: this.props.type });
    this.props.openClaimPopup();
  };

  loadMyClaimsType = async (type: string): Promise<BonusClaimDto[]> => {
    const result = await PortCentralServer.Repo.client.getMyClaimsType(type);

    return result;
  };

  render() {
    // console.log(BONUS_TYPES[`${this.props.type}`])
    // console.log(
    //   "disabled",
    //   this.state.kycBonusButtonState === BonusClaimStatus.APPROVED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.PROCESSED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.REJECTED
    //     ? true
    //     : false
    // );

    return (
      <React.Fragment>
        <div
          className="container bonus-card col-md-5"
          style={{
            height: "fit-content",
            borderRadius: "15px",
            border: "1px solid #ffffff1a",
            padding: "30px"
          }}
        >
          <a href={this.props.type === "BOOST" ? "https://www.bonex.net/en_US/cms/rewards-hub" : this.props.href}>
            <img
              className="usdt-bonus-card"
              src="/images/bonus-card-usdt-image.png"
              style={{
                width: "80px",
                float:"right"
              }}
            />
          </a>
          <p
            style={{
              color: "white"
            }}
          >
            {this.state.smallHeading}
          </p>
          <h5
            style={{
              color: "white",
              height: "60px"
            }}
          >
            <strong
              style={{
                fontSize: "30px"
              }}
            >
              {this.state.value}
            </strong>{" "}
            {this.state.valueText}
          </h5>
          {this.props.isLogged ? (
            <p
              style={{
                color: "white",
                margin: "28px 0px",
                fontSize: "16px",
                width: "85%",
                height: "48px"
              }}
            >
              You will receive: {this.props.generatedBonus} USDT
            </p>
          ) : (
            ""
          )}

          <p
            style={{
              color: "white",
              margin: "28px 0px",
              fontSize: "16px",
              width: "85%",
              height: "48px"
            }}
          >
            {this.state.subtitle}
          </p>
          {this.props.isLogged ? (
            // <button
            //   disabled={!this.props.canClaimYn}
            //   onClick={() => this.onClaimButtonClick()}
            //   style={{
            //     width: "95%",
            //     margin: "auto",
            //     backgroundColor: "#D22AEB",
            //     border: "none",
            //     borderRadius: "5px"
            //   }}
            // >
            //   {this.state.buttonTitle}
            // </button>
            <div
              className={`${!this.props.canClaimYn ? "tooltip-container" : ""}`}
              style={{
                // width: "32%",
                // height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <button
                disabled={!this.props.canClaimYn || this.props.generatedBonus === 0}
                className="claim-bonus-button-new"
                onClick={() => this.onClaimButtonClick()}
                style={{
                  width: "95%",
                  margin: "auto",
                  backgroundColor: "#D22AEB",
                  border: "none",
                  borderRadius: "5px"
                }}
              >
                {this.state.buttonTitle}
              </button>
              <div className={`${true ? "tooltip-bonus-button" : "display-none"}`}>{this.props.message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
