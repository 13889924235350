import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import LineChartComponent from './LineChartComponent';

import "react-toastify/dist/ReactToastify.css";
import { HomePageNew } from "./pages/HomePageNew";

// Pages

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/" element={<HomePageNew />} />
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
