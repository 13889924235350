import React, { FormEvent } from "react";
import { AppBonusCommissionSurveyRequestDto } from "../lib/drivers/dto/AppBonusCommissionSurveyRequestDto";
import { AppBonusClaimRankingInfo } from "../lib/drivers/dto/AppBonusClaimRankingInfo";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";

const surveyQuestions = [
  {
    question: "How much do you expect to earn with our affiliate program per month?",
    answers: ["Less than $500", "$500 - $1,000", "$1,000 - $5,000", "More than $5,000"]
  },
  {
    question: "What is your favourite social media platform for promoting affiliate links?",
    answers: ["YouTube", "Twitter", "Facebook", "Instagram", "TikTok"]
  },
  {
    question: "What is your favourite cryptocurrency to trade or invest in?",
    answers: ["Bitcoin (BTC)", "Ethereum (ETH)", "Ripple (XRP)", "Litecoin (LTC)"]
  },
  {
    question: "How often do you engage with your audience on social media or community platforms?",
    answers: ["Multiple times a day", "Once a day", "A few times a week", "Once a week", "Less than once a week"]
  },
  {
    question: "What type of content do you primarily create or share with your audience?",
    answers: [
      "Educational content (tutorials, guides)",
      "Market analysis and updates",
      "Personal insights and opinions",
      "News and announcements",
      "Entertaining content (memes, fun videos)"
    ]
  },
  {
    question: "Which community platform do you use the most to engage with your followers?",
    answers: ["Telegram", "Reddit", "Facebook Groups", "Discord"]
  },
  {
    question: "How did you first hear about our affiliate program?",
    answers: ["Social media", "Friend or colleague", "Online search", "Advertisement"]
  },
  {
    question: "What motivates you the most to participate in our affiliate program?",
    answers: ["Earning extra income", "Sharing valuable resources with my audience", "Expanding my influence in the crypto community", "Receiving special rewards and bonuses"]
  }
];

// Props & State
export type RankingPopupProps = {
  closePopup(): void;
  ranking: Array<AppBonusClaimRankingInfo>
  // saveSurvey(model: AppBonusCommissionSurveyRequestDto): void;
};

type RankingPopupState = {
  selectedAnswers: { [key: number]: string[] };
  allQuestionsAnswered: boolean;
  // ranking: Array<any>;
};

// interface RankingPopupState {
//     selectedAnswers: { [key: number]: string[] };
//   }

export class RankingPopup extends React.Component<RankingPopupProps, RankingPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedAnswers: {},
      allQuestionsAnswered: false,
      // ranking: []
    };
  }

  // componentDidMount(): void {}

  componentDidMount = async () => {
    const bonusRankings: AppBonusClaimRankingInfo[] = await this.loadBonusClaimRanking();
    // const dataCurrIndexItemNew: BonusCommissionIndexDto = await this.loadBonusCommissionIndexCurr();
    // console.log("rankings", bonusRankings);
    this.setState({
      ...this.state,
      // ranking: bonusRankings
    });
  };

  loadBonusClaimRanking = async (): Promise<AppBonusClaimRankingInfo[]> => {
    const result = await PortCentralServer.Repo.client.getBonusClaimRanking();
    return result;
  };

  // handleChange = (questionIndex: number, answer: string) => {
  //     this.setState((prevState) => {
  //         const currentAnswers = prevState.selectedAnswers[questionIndex] || [];
  //         if (currentAnswers.includes(answer)) {
  //             return {
  //                 selectedAnswers: {
  //                     ...prevState.selectedAnswers,
  //                     [questionIndex]: currentAnswers.filter(a => a !== answer)
  //                 }
  //             };
  //         } else {
  //             return {
  //                 selectedAnswers: {
  //                     ...prevState.selectedAnswers,
  //                     [questionIndex]: [...currentAnswers, answer]
  //                 }
  //             };
  //         }
  //     });
  // };

  handleChange = (questionIndex: number, answer: string) => {
    this.setState((prevState) => {
      const currentAnswers = prevState.selectedAnswers[questionIndex] || [];
      let updatedAnswers;
      if (currentAnswers.includes(answer)) {
        updatedAnswers = currentAnswers.filter((a) => a !== answer);
      } else {
        updatedAnswers = [...currentAnswers, answer];
      }

      const updatedSelectedAnswers = {
        ...prevState.selectedAnswers,
        [questionIndex]: updatedAnswers
      };

      const allQuestionsAnswered = surveyQuestions.every((_, index) => {
        return updatedSelectedAnswers[index] && updatedSelectedAnswers[index].length > 0;
      });

      return {
        selectedAnswers: updatedSelectedAnswers,
        allQuestionsAnswered: allQuestionsAnswered
      };
    });
  };

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const allQuestionsAnswered = surveyQuestions.every((_, index) => {
      return this.state.selectedAnswers[index] && this.state.selectedAnswers[index].length > 0;
    });

    if (!allQuestionsAnswered) {
      alert("Please answer all questions.");
      return;
    }

    const selectedAnswers = surveyQuestions.map((questionObj, index) => ({
      question: questionObj.question,
      answers: this.state.selectedAnswers[index] || []
    }));

    const request = { items: selectedAnswers };
    // this.props.saveSurvey(request);
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <div
            className="login-popup-wrapper"
            style={{
              position: "absolute",
              top: "0%",
              left: "0%",
              width: "100%",
              height: "231%",
              backdropFilter: "blur(10px)",
              zIndex: "5"
            }}
          >
            <div className="rank-popup" style={{ height: "33%", marginTop: "10%" }}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white"
                }}
                onClick={() => this.props.closePopup()}
              >
                X
              </button>
              <h3
                style={{
                  width: "80%",
                  height: "30px",
                  margin: "auto",
                  color: "white",
                  // marginTop: "20px",
                  textAlign: "center"
                }}
              >
                {/* Affiliate Program Interest Quiz */}
                {/* AFFILIATE PROGRAM <strong style={{ color: "#8d24d3" }}>INTEREST QUIZ</strong> */}
                BONUS RANKING
              </h3>
              <div
                style={{
                  width: "86%",
                  height: "71%",
                  overflow: "auto",
                  margin: "auto",
                  marginTop: "80px"
                }}
              >
                {/* <form onSubmit={this.handleSubmit}> */}
                
                <div
                  style={{
                    // width: "fit-content",
                    margin: "auto",
                    marginTop: "12px"
                  }}
                >
                  {/* <button
                        style={{
                          border: "none",
                          backgroundColor: "#9840cd",
                          borderRadius: "3px",
                          color: "white",
                          padding: "3px 16px"
                        }}
                        type="submit"
                        // disabled={!this.state.allQuestionsAnswered}
                      >
                        Submit
                      </button> */}
                  <table className="table table-dark" style={{backgroundColor: "transparent"}}>
                    <thead>
                      <tr>
                        <th style={{textAlign: "center"}} scope="col">#</th>
                        <th style={{textAlign: "center"}} scope="col">User</th>
                        <th style={{textAlign: "center"}} scope="col">Bonus</th>
                        {/* <th scope="col">Handle</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.ranking.length > 0
                        ? this.props.ranking.map((item, index) => (
                            <tr>
                              {/* <th scope="row">1</th> */}
                              <td style={{textAlign: "center"}}>{item.rank}</td>
                              <td style={{textAlign: "center"}}>{item.uid}</td>
                              <td style={{textAlign: "center"}}>{item.bonus.toFixed(2)} USDT</td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
