import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import "bootstrap/dist/js/bootstrap.bundle.min"
import { AppProvider } from "../context/AppProvider";

type LayoutProps = {
  children: any;
};

type LayoutState = {};

export class Layout extends React.Component<LayoutProps, LayoutState> {
  private isMobile: boolean;
  private leftSideBarTheme: string = "dark";
  private leftSideBarType: string = "default";

  constructor(props: LayoutProps) {
    super(props);
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  //#region
  toggleMenuCallback() {
    // TODO : Implement me
  }
  // #endregion

  // #region React Events
  componentDidMount() { }
  // #endregion

  render() {
    return (
      <AppProvider>
        <React.Fragment>
          <div className="main-wrapper">
            <Header toggleMenuCallback={this.toggleMenuCallback} />

            <div className="content-body" style={{ margin: "auto" }}>
              <div className="s">{this.props.children}</div>
            </div>

            <Footer />
          </div>
        </React.Fragment>
      </AppProvider>
    );
  }
}
