import React from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { BonusClaimDto } from "../lib/drivers/dto/BonusClaimDto";
import { BonusClaimStatus } from "../lib/drivers/dto/enums/BonusClaimStatus";
import { BonusCard } from "./BonusCard";
import { AppBonusUserInfoDto } from "../lib/drivers/dto/AppBonusUserInfoDto";
import { AppBonusUserClaimInfoDto } from "../lib/drivers/dto/AppBonusUserClaimInfoDto";

export type BonusesPartNewProps = {
  claimBonus(type: any): void;
  openClaimPopup(): void;
  isLogged: boolean;
  // scrollToTop(): void;
};

type BonusesPartNewState = {
  kycBonusButtonState: BonusClaimStatus;
  allBonusData: Array<AppBonusUserClaimInfoDto>;
};

export class BonusesPartNew extends React.Component<BonusesPartNewProps, BonusesPartNewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      kycBonusButtonState: BonusClaimStatus.NOT_SET,
      allBonusData: []
    };
  }

  componentDidMount = async () => {
    try {
      let newState = this.state;
      const dataKycBonusClaimList: BonusClaimDto[] = await this.loadMyClaimsType("KYC");
      const dataBonuses: AppBonusUserClaimInfoDto[] = await this.loadBonuses();
      // console.log("these are the bonuses", dataBonuses);
      // console.log("these are the result from the server", dataBonuses);

      newState = {
        ...this.state,
        allBonusData: dataBonuses
      };

      if (dataKycBonusClaimList && dataKycBonusClaimList.length !== 0) {
        newState = {
          ...newState,
          allBonusData: dataBonuses
        };
      }

      this.setState(newState);
    } catch (e: any) {
      console.log(e);
    }
  };

  loadBonuses = async (): Promise<AppBonusUserClaimInfoDto[]> => {
    const result = await PortCentralServer.Repo.client.getBonusesForClaim();
    // console.log("these are the result from the server", result);
    // if (result) {
    // this.setState({
    //   ...this.state,
    //   allBonusData: result
    // });
    // }

    return result;
  };

  onClaimButtonClick = () => {
    this.props.claimBonus({ bonusType: "KYC" });
    this.props.openClaimPopup();
  };

  loadMyClaimsType = async (type: string): Promise<BonusClaimDto[]> => {
    const result = await PortCentralServer.Repo.client.getMyClaimsType(type);

    return result;
  };

  render() {
    // console.log(
    //   "disabled",
    //   this.state.kycBonusButtonState === BonusClaimStatus.APPROVED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.PROCESSED ||
    //     this.state.kycBonusButtonState === BonusClaimStatus.REJECTED
    //     ? true
    //     : false
    // );

    // console.log("state when rendering", this.state.allBonusData);

    return (
      <React.Fragment>
        <div className="">
          <div
            className="row"
            style={{
              backgroundColor: "#060010",
              height: "fit-content",
              width: "100%",
              margin: "auto",
              padding: "50px 8%"
            }}
          >
            <h2
              style={{
                color: "white"
              }}
            >
              One Portal for Multiple Rewards
            </h2>
            <div
              className="row"
              style={{
                backgroundColor: "#060010",
                width: "100%",
                margin: "auto",
                padding: "50px 0%"
              }}
            >
              {/* <BonusCard isLogged={this.props.isLogged} openClaimPopup={this.props.openClaimPopup} claimBonus={this.props.claimBonus} type={"COMMISSION"} />
              <BonusCard isLogged={this.props.isLogged} openClaimPopup={this.props.openClaimPopup} claimBonus={this.props.claimBonus} type={"BOOST"} />
              <BonusCard isLogged={this.props.isLogged} openClaimPopup={this.props.openClaimPopup} claimBonus={this.props.claimBonus} type={"KYC"} /> */}
              {this.state.allBonusData ? (
                this.state.allBonusData.map((bonus, index) => (
                  <BonusCard
                    key={index}
                    isLogged={this.props.isLogged}
                    openClaimPopup={this.props.openClaimPopup}
                    claimBonus={this.props.claimBonus}
                    type={bonus.bonusType}
                    canClaimYn={bonus.canClaimYn}
                    message={bonus.message}
                    generatedBonus={bonus.bonus}
                    href=""
                  />
                ))
              ) : (
                <>
                  <BonusCard
                    isLogged={this.props.isLogged}
                    openClaimPopup={this.props.openClaimPopup}
                    claimBonus={this.props.claimBonus}
                    type={"COMMISSION"}
                    canClaimYn={false}
                    message={""}
                    generatedBonus={0}
                    href={""}
                  />
                  <BonusCard
                    isLogged={this.props.isLogged}
                    openClaimPopup={this.props.openClaimPopup}
                    claimBonus={this.props.claimBonus}
                    type={"BOOST"}
                    canClaimYn={false}
                    message={""}
                    generatedBonus={0}
                    href={"https://www.bonex.net/en_US/cms/rewards-hub"}
                  />
                  {/* <BonusCard
                    isLogged={this.props.isLogged}
                    openClaimPopup={this.props.openClaimPopup}
                    claimBonus={this.props.claimBonus}
                    type={"KYC"}
                    canClaimYn={false}
                    message={""}
                    generatedBonus={0}
                  /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
