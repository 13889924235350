import React from "react";

// Props & State
export type FooterProps = {};

type FooterState = {};

export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <div
          className="footer"
          style={{
            padding: "0px",
            height: "142px",
            backgroundColor: "#00000063"
            // marginTop: "54px",
          }}
        >
          <div className="copyright" style={{ paddingTop: "30px", textAlign: "center" }}>
            <div style={{ textDecoration: "none", color: "white", marginBottom: "25px" }}>
              © {new Date().getFullYear()}{" "}
              <a href="https://www.bonex.net/" target="_blank" style={{ textDecoration: "none", color: "white" }}>
                BoneX Margin
              </a>
            </div>
            <div style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <span>Your assets are safe with</span>{" "}
              <a href="https://www.bonex.net/en_US/cms/fireblocks" style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                <img src="/images/vendors/fireblocks-logo.svg" alt="Fireblocks" style={{ height: "24px" }} />
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
