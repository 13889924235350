import React, { FormEvent } from "react";
import { AppBonusCommissionSurveyRequestDto } from "../lib/drivers/dto/AppBonusCommissionSurveyRequestDto";

// Props & State
export type ClaimBonusPopupProps = {
  // closePopup(): void;
  // saveSurvey(model: AppBonusCommissionSurveyRequestDto): void;
  message: string;
  closePopup(): void;
};

type ClaimBonusPopupState = {
  selectedAnswers: { [key: number]: string[] };
  allQuestionsAnswered: boolean;
  // message: string
};

// interface ClaimBonusPopupState {
//     selectedAnswers: { [key: number]: string[] };
//   }

export class ClaimBonusPopup extends React.Component<ClaimBonusPopupProps, ClaimBonusPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedAnswers: {},
      allQuestionsAnswered: false
    };
  }

  componentDidMount(): void {}

  render() {
    return (
      <React.Fragment>
        <div>
          <div
            className="bonus-popup-wrapper"
            style={{
              position: "absolute",
              bottom: "-170%",
              left: "0%",
              width: "100%",
              height: "231%",
              backdropFilter: "blur(10px)",
              zIndex: "5"
            }}
          >
            <div className="bonus-popup">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white"
                }}
                onClick={() => this.props.closePopup()}
              >
                X
              </button>
              <h3
                style={{
                  width: "80%",
                  height: "30px",
                  margin: "auto",
                  color: "white",
                  // marginTop: "20px",
                  textAlign: "center",
                  marginTop: "1%"
                }}
              >
                <strong style={{ color: "rgb(164 83 221)" }}>Hurray!</strong>
                {/* {this.props.message !== "" ? this.props.message : "Your claim is being processed."} */}
              </h3>
              <div
                style={{
                  margin: "auto",
                  height: "fit-content",
                  width: "fit-content",
                  marginTop: "20%"
                }}
              >
                <img
                  src="images/purple-present-image.png"
                  style={{
                    width: "200px",
                    transform: "rotate(-18deg)"
                  }}
                />
              </div>
              <div
                style={{
                  width: "86%",
                  height: "71%",
                  overflow: "auto",
                  margin: "auto",
                  marginTop: "80px"
                }}
              ></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
