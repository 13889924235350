import { destroyCookie, parseCookies, setCookie } from 'nookies';

export const COOKIE_EXPIRATION_TIME = 60 * 60 * 24 // 1 day
export const TOKEN_COOKIE = 'bonuses.token'
export const REFRESH_TOKEN_COOKIE = 'bonuses.refreshToken'
export const COOKIE_SURVEY_SKIPPED = "bonuses.survey.answered"

export const COOKIE_EXCHANGE_TOKEN = "token";

type CreateSessionCookiesParams = {
  token?: string;
  refreshToken?: string;
};


export class AuthCookieManager {
  static createSessionCookies(params: CreateSessionCookiesParams) {
    const { token, refreshToken } = params;

    if (token) {
      setCookie(null, TOKEN_COOKIE, token, {
        maxAge: COOKIE_EXPIRATION_TIME,
        path: '/',
      });
    }

    if (refreshToken) {
      setCookie(null, REFRESH_TOKEN_COOKIE, refreshToken, {
        maxAge: COOKIE_EXPIRATION_TIME,
        path: '/',
      });
    }


  }

  static createSurveySessionCookies() {

    setCookie(null, COOKIE_SURVEY_SKIPPED, "true", {
      maxAge: COOKIE_EXPIRATION_TIME,
      path: '/',
    });
  }

  static removeSessionCookies() {
    destroyCookie(null, TOKEN_COOKIE, { path: '/', });
    destroyCookie(null, REFRESH_TOKEN_COOKIE, { path: '/', });
  }

  static removeExchangeCookies() {
    destroyCookie(null, COOKIE_EXCHANGE_TOKEN, { path: '/', domain: '.bonex.net' });
  }

  static getToken(): string | undefined {
    const cookies = parseCookies();

    return cookies[TOKEN_COOKIE];
  }

  static getRefreshToken(): string | undefined {
    const cookies = parseCookies();
    return cookies[REFRESH_TOKEN_COOKIE];
  }

  static getExchangeToken(): string | undefined {
    const cookies = parseCookies();
    return cookies[COOKIE_EXCHANGE_TOKEN];
  }

  static getSurveySkippedValue(): boolean {
    const cookies = parseCookies();
    return cookies[COOKIE_SURVEY_SKIPPED] == "true";
  }
}


