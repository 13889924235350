import React, { createRef, RefObject } from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { AppBonusClaimRankingInfo } from "../lib/drivers/dto/AppBonusClaimRankingInfo";

// Props & State
export type HeadingPartNewProps = {
  totalBonusAmount: number;
  commissionPercentage: number;
  invitedFirends: number;
  invitedFriendsKYC: number;
  inviteCode: string;
  onQrCodeButtonClick(): void;
  isLogged: boolean;
  onOpenRankPopupClick(): void;
  ranking: Array<AppBonusClaimRankingInfo>;
};

type HeadingPartNewState = {
  rankingPopupState: {
    isOpen: boolean;
  };
  screenWidth: number;
  isCoppiedValue: boolean;
};

export class HeadingPartNew extends React.Component<HeadingPartNewProps, HeadingPartNewState> {
  textInput: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      rankingPopupState: {
        isOpen: false
      },
      screenWidth: window.innerWidth,
      isCoppiedValue: false
    };

    this.textInput = createRef();
  }

  // componentDidMount(): void {}

  // componentDidMount = async () => {
  //   const bonusRankings: AppBonusClaimRankingInfo[] = await this.loadBonusClaimRanking();
  //   // const dataCurrIndexItemNew: BonusCommissionIndexDto = await this.loadBonusCommissionIndexCurr();
  // };

  // loadBonusClaimRanking = async (): Promise<AppBonusClaimRankingInfo[]> => {
  //   const result = await PortCentralServer.Repo.client.getBonusClaimRanking();
  //   return result;
  // };

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  copyToClipboard = async () => {
    if (this.state.screenWidth > 450) {
      const copyText = this.textInput.current;
      if (copyText) {
        try {
          await navigator.clipboard.writeText(copyText.value);
          this.setState({
            ...this.state,
            isCoppiedValue: true
          });
          alert("Copied the text: " + copyText.value);
        } catch (err) {
          alert("Failed to copy text: " + err);
        }
      }
    } else {
      const copyText = `https://bonex.net/register?inviteCode=${this.props.inviteCode}`;

      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      // document.execCommand("copy");

      // Alert the copied text (optional)
      if (copyText) {
        try {
          await navigator.clipboard.writeText(copyText);
          this.setState({
            ...this.state,
            isCoppiedValue: true
          });
          alert("Copied the text: " + copyText);
        } catch (err) {
          alert("Failed to copy text: " + err);
        }
      }

      alert("Copied the text: " + copyText);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="conatiner heading-container-new wrapper" style={{ margin: "0 0 0px" }}>
          <div className="row gradient-background-animated" style={{ width: "100%", marginRight: "0px", marginLeft: "0px" }}>
            <div
              className="col-md-8 col-sm-7 main-heading"
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignContent: "flex-start",
                alignItems: "flex-start",
                height: "387px",
                paddingTop: "58px",
                paddingLeft: "127px",
                justifyContent: "space-evenly"
              }}
            >
              {/* <h2
                style={{
                  color: "white",
                  fontWeight: "400",
                  width: "80%",
                  marginBottom: "3px"
                }}
              >
                1. <strong>Invite</strong> friends
              </h2> */}
              <h2
                // className="glow"
                style={{
                  color: "white",
                  fontWeight: "400",
                  width: "80%",
                  marginBottom: "3px",
                  zIndex: "2"
                }}
              >
                Boost your trading with bonuses of up to <strong>10,000 USDT</strong>!
                {/* Earn up to 10,000 USDT</strong> on your deposits. Enjoy <strong>50%</strong> Commission from your referrals. */}
              </h2>
              {/* <h2
                style={{
                  color: "white",
                  fontWeight: "400",
                  width: "80%",
                  marginBottom: "20px"
                }}
              >
                3. Verify - <strong>get 20 USDT</strong>
              </h2> */}
              {/* <h5
                className="glow"
                style={{
                  color: "#ffa817",
                  fontWeight: "500",
                  marginBottom: "30px",
                  zIndex: "2",
                  fontSize: "26px",
                  fontFamily: "Brush Script MT, cursive"
                }}
              >
                Limited time offer!
              </h5> */}
              <img style={{ minWidth: "221px", maxWidth: "443px", position: "relative", zIndex: "2" }} src="/images/limited-time-offer-image-1.png" />
              {this.props.isLogged ? (
                <div
                  className="invite-input-wrapper"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    minWidth: "583px",
                    maxWidth: "630px",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <label
                      className="invitation-input-label"
                      style={{
                        color: "white",
                        position: "absolute",
                        marginTop: "7px",
                        marginLeft: "10px",
                        zIndex: "2"
                      }}
                    >
                      Invitation Link
                    </label>
                    <input
                      disabled
                      ref={this.textInput}
                      value={this.state.screenWidth > 450 ? `https://bonex.net/register?inviteCode=${this.props.inviteCode}` : `${this.props.inviteCode}`}
                      style={{
                        zIndex: "2",
                        position: "relative",
                        color: "white",
                        height: "40px",
                        backgroundColor: "transparent",
                        width: "100%",
                        border: "1px solid #cd7aff",
                        borderRadius: "5px",
                        margin: "auto",
                        paddingLeft: "125px",
                        // marginTop: "10px"
                        textAlign: "end",
                        paddingRight: "10px"
                      }}
                    />
                  </div>
                  <button
                    style={{
                      position: "relative",
                      zIndex: "2",
                      backgroundColor: "transparent",
                      border: "1px solid #cd7aff",
                      borderRadius: "5px",
                      color: "white",
                      padding: "0px 10px",
                      height: "40px",
                      marginLeft: "10px"
                    }}
                    onClick={this.copyToClipboard}
                  >
                    {this.state.isCoppiedValue ? <i className="fa-solid fa-copy"></i> : <i className="fa-regular fa-copy"></i>}
                  </button>
                </div>
              ) : (
                ""
              )}
              {
                this.props.isLogged ? (
                  <button
                    onClick={this.props.onQrCodeButtonClick}
                    className="heading-button"
                    style={{
                      padding: "4px 18px",
                      // backgroundColor: "purple",
                      border: "none",
                      borderRadius: "20px",
                      color: "white",
                      zIndex: "2"
                    }}
                  >
                    {" "}
                    Invite Friends
                  </button>
                ) : (
                  <button
                    onClick={() => window.open("https://www.bonex.net/en_US/login")}
                    className="heading-button"
                    style={{
                      padding: "4px 18px",
                      // backgroundColor: "purple",
                      border: "none",
                      borderRadius: "20px",
                      color: "white",
                      zIndex: "2"
                    }}
                  >
                    {" "}
                    Login in Bonex.net
                  </button>
                )
                // <a href="https://www.bonex.net/en_US/login">Log in Bonex.net</a>
              }
              <img className="btc-planet-mobile" style={{ width: "90%", zIndex: "1" }} src="/images/bitcoin-planet-new.png"></img>
            </div>
            <div
              className="col-md-4 col-sm-5"
              style={{
                padding: "0px",
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end"
              }}
            >
              {/* <img
                className="bonuses-friends-hero friends-icon"
                style={{
                  width: "40%",
                  position: "relative"
                }}
                src="/images/bonuses-friends-hero.png"
              ></img> */}
              {/* These will be added tomorrow */}
              {/* <img style={{position: "relative", top: "50px"}} src="/images/bonuses-hero-usdt-image-1.png"></img> */}
              <img className="btc-planet" style={{ width: "90%", zIndex: "2" }} src="/images/bitcoin-planet-new.png"></img>
              {/* <img className="usdt-icon-1" style={{marginLeft: "-52px"}} src="/images/bonuses-hero-usdt-image-1.png" />
              <img className="usdt-icon-2" style={{marginLeft: "160px"}} src="/images/bonuses-hero-usdt-image-2.png" /> */}
            </div>
          </div>
          <div
            className="row rank-rows"
            style={{
              width: "100%",
              backgroundColor: "#491370",
              height: "40px",
              margin: "auto",
              position: "relative",
              zIndex: "2"
            }}
          >
            <div
              className="col-md-5"
              style={{
                color: "white",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {this.props.ranking && this.props.ranking.length > 0 ? (
                <p style={{ padding: "0", margin: "0" }}>
                  {this.props.ranking[0].uid} Earned <strong> {this.props.ranking[0].bonus} USDT </strong> in Commissions.
                </p>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-md-5"
              style={{
                color: "white",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "left"
              }}
            >
              {this.props.ranking && this.props.ranking.length > 0 ? (
                <p style={{ padding: "0", margin: "0" }}>
                  {this.props.ranking[1].uid} Earned <strong> {this.props.ranking[1].bonus} USDT </strong> in Commissions.
                </p>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-md-2"
              style={{
                color: "white",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "left"
              }}
            >
              <button
                onClick={() => this.props.onOpenRankPopupClick()}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white"
                }}
              >
                <strong>View Ranking</strong>
              </button>
            </div>
          </div>
          {/* <div className=""></div> */}
        </div>
      </React.Fragment>
    );
  }
}
