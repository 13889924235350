import React from "react";
import { Link } from "react-router-dom";

// Reactstrap

// Import menuDropdown
import { AppContext } from "../context/AppProvider";

// Props & State
export interface HeaderProps {
  toggleMenuCallback(): void;
}

type HeaderState = {
  megaMenu: boolean;
  isNavExpanded: boolean;
};

export class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state = {
    megaMenu: false,
    isNavExpanded: false
  };

  // #region State Setters
  // #endregion

  // #region handlers
  toggleNav = () => {
    this.setState((prevState) => ({
      isNavExpanded: !prevState.isNavExpanded
    }));
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        {/* <div className="nav-header">
          <a href="index.html" className="brand-logo">
            <span className="logo-abbr">Q</span>
            <span className="logo-compact">Corbin</span>
            <span className="brand-title">Corbin</span>
          </a>
          <div className="nav-control">
            <div className="hamburger">
              <span className="toggle-icon">
                <i className="icon-menu" />
              </span>
            </div>
          </div>
        </div> */}
        {/* <img src="images/logo-header-color.svg"/> */}
        <header className="header" style={{ padding: "0px 0px" }}>
          <div
            style={{
              width: "fit-content",
              margin: "auto",
              height: "100%",
              padding: "18px"
            }}
          >
            <a href="https://www.bonex.net/">
              <img src="images/logo-header-color.svg" />
            </a>
          </div>
          {/* <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div className="search_bar dropdown">
                    <span
                      className="search_icon d-md-none p-3 c-pointer"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-search" />
                    </span>
                    <div className="dropdown-menu p-0 m-0">
                      <form className="form-inline">
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <ul className="navbar-nav header-right">
                  <li className="nav-item dropdown notification_dropdown">
                    <a
                      className="nav-link"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-bell" />
                      <span className="badge badge-primary">3</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <h5 className="notification_title">Notifications</h5>
                      <ul className="list-unstyled">
                        <li className="media dropdown-item">
                          <img
                            className="mr-3"
                            src="./images/avatar/1.jpg"
                            alt="Quixlab"
                          />
                          <div className="media-body">
                            <a href="#">
                              <div className="d-flex justify-content-between">
                                <h5>Mr John</h5>
                              </div>
                              <p className="m-0">signed nup now</p>
                            </a>
                          </div>
                        </li>
                        <li className="media dropdown-item">
                          <img
                            className="mr-3"
                            src="./images/avatar/8.jpg"
                            alt="Quixlab"
                          />
                          <div className="media-body">
                            <a href="#">
                              <div className="d-flex justify-content-between">
                                <h5>Lisa Heiden</h5>
                              </div>
                              <p className="m-0">Reset password</p>
                            </a>
                          </div>
                        </li>
                        <li className="media dropdown-item">
                          <img
                            className="mr-3"
                            src="./images/avatar/2.jpg"
                            alt="Quixlab"
                          />
                          <div className="media-body">
                            <a href="#">
                              <div className="d-flex justify-content-between">
                                <h5>Mr khan</h5>
                              </div>
                              <p className="m-0">Email sent</p>
                            </a>
                          </div>
                        </li>
                      </ul>
                      <a className="all-notification" href="#">
                        All Notifications
                      </a>
                    </div>
                  </li>
                  <li className="nav-item dropdown header-profile">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-account" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        <span>My profile</span>
                      </a>
                      <a href="#!" className="dropdown-item">
                        <span>Calender</span>
                      </a>
                      <a href="#!" className="dropdown-item">
                        <span>Inbox</span>
                      </a>
                      <a href="#!" className="dropdown-item">
                        <span>Settings</span>
                      </a>
                      <a href="#!" className="dropdown-item">
                        <span>Logout</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div> */}
        </header>
      </React.Fragment>
    );
  }
}
